@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
}
